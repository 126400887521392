body {
  margin: 0;
  padding: 0;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  src: url(https://github.com/tonsky/FiraCode/blob/master/distr/ttf/FiraCode-Light.ttf);
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
tr:hover {
  background-color: #f5f5f5;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
th {
  background-color: #5da2d5;
  color: white;
}
th,
td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.definationHeader {
  width: 70%;
}
.fixed_header thead {
  display: table;
  width: 100%;
}
.fixed_header {
  width: 72%;
  align-self: center;
  align-content: center;
  table-layout: fixed;
  border-collapse: collapse;
  /* overflow-y: scroll; */
  /* height: 400px; */
  display: block;
}
.fixed_header tr {
  display: table;
  box-sizing: border-box;
  width: 100%;
}
.fixed_header tbody {
  overflow: auto;
  /* height: 200px; */
  width: 100%;
  display: block;
  max-height: 400px;
  overflow-y: scroll;
  /* display: block; */

  align-self: center;
  align-content: center;
  table-layout: fixed;
  border-collapse: collapse;
}
.key {
  width: 36%;
}

/* .fixed_header thead {
  display: block;
} */

.fixed_header thead {
  background: black;
  color: #fff;
}

.fixed_header th,
.fixed_header td {
  padding: 5px;
  text-align: left;
  /* width: 400px; */
}
.button1 {
  font-size: 16px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: white;
  padding: 10px 28px;
  background-color: #5da2d5;
}
.button1:hover {
  background-color: white; /* Green */
  color: #5da2d5;
  cursor: pointer;
}

.upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.upload + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
}
.upload:focus + label,
.upload + label {
  cursor: pointer; /* "hand" cursor */
}

.goToNextLink {
  text-align: center;
  color: #5da2d5;
}

.button1 {
  font-size: 16px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: white;
  padding: 10px 18px;
  background-color: #5da2d5;
}
.button1:hover {
  background-color: white; /* Green */
  color: #5da2d5;
  cursor: pointer;
}

.okButton {
  font-size: 12px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: white;
  background-color: #5da2d5;
}
.okButton:hover {
  background-color: white; /* Green */
  color: #5da2d5;
  cursor: pointer;
}
.upload {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.upload + label {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
}
.upload:focus + label,
.upload + label {
  cursor: pointer; /* "hand" cursor */
}

* {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  /* background-color:; */
}
.ulStyle {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #ececec;
}
.liStyle {
  float: left;
}

li link {
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
  background-color: grey;
}

