@font-face {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  src: url(https://github.com/tonsky/FiraCode/blob/master/distr/ttf/FiraCode-Light.ttf);
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
tr:hover {
  background-color: #f5f5f5;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
th {
  background-color: #5da2d5;
  color: white;
}
th,
td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.definationHeader {
  width: 70%;
}
.fixed_header thead {
  display: table;
  width: 100%;
}
.fixed_header {
  width: 72%;
  align-self: center;
  align-content: center;
  table-layout: fixed;
  border-collapse: collapse;
  /* overflow-y: scroll; */
  /* height: 400px; */
  display: block;
}
.fixed_header tr {
  display: table;
  box-sizing: border-box;
  width: 100%;
}
.fixed_header tbody {
  overflow: auto;
  /* height: 200px; */
  width: 100%;
  display: block;
  max-height: 400px;
  overflow-y: scroll;
  /* display: block; */

  align-self: center;
  align-content: center;
  table-layout: fixed;
  border-collapse: collapse;
}
.key {
  width: 36%;
}

/* .fixed_header thead {
  display: block;
} */

.fixed_header thead {
  background: black;
  color: #fff;
}

.fixed_header th,
.fixed_header td {
  padding: 5px;
  text-align: left;
  /* width: 400px; */
}
.button1 {
  font-size: 16px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: white;
  padding: 10px 28px;
  background-color: #5da2d5;
}
.button1:hover {
  background-color: white; /* Green */
  color: #5da2d5;
  cursor: pointer;
}

.upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.upload + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
}
.upload:focus + label,
.upload + label {
  cursor: pointer; /* "hand" cursor */
}

.goToNextLink {
  text-align: center;
  color: #5da2d5;
}
