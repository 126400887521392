.button1 {
  font-size: 16px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: white;
  padding: 10px 18px;
  background-color: #5da2d5;
}
.button1:hover {
  background-color: white; /* Green */
  color: #5da2d5;
  cursor: pointer;
}

.okButton {
  font-size: 12px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: white;
  background-color: #5da2d5;
}
.okButton:hover {
  background-color: white; /* Green */
  color: #5da2d5;
  cursor: pointer;
}
.upload {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.upload + label {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
}
.upload:focus + label,
.upload + label {
  cursor: pointer; /* "hand" cursor */
}
